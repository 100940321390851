import React, { useState, useEffect } from 'react';
import { TextField, Select, MenuItem, Button } from '@mui/material';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { useAuth0 } from "@auth0/auth0-react";
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import { useParams } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ConfirmDialog from '../components/ConfirmDialog';


//ACTIONS WIT actionParamters and actionParameterArray
const actionParametersType = new Set<string>(["click", "hide"]);
const actionParameterArrayType = new Set<string>(["cookieDelete", "cookieSet","removeCSS", "setCSS"]);


// DYNAMIC FORM PROPERTIES
//*************************
interface FormField {
  id: number;
  actionType: string;
  actionParameters: string;
  actionParameterArray?: Record<string, string>;
}
  // Record<string, string>;


const initialFormData: FormField[] = [
  { id: 1, actionType: 'hide', actionParameters: '', actionParameterArray: { key:"value" } },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// MAIN OBJECT
//*************************
const DynamicForm: React.FC = () => {
  const { url } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [formDataNone, setFormDataNone] = useState<FormField[]>([]);
  const [formDataBalanced, setFormDataBalanced] = useState<FormField[]>([]);
  const [formDataAll, setFormDataAll] = useState<FormField[]>([]);
  const [urlInput, setUrlInput] = useState(url !== undefined? url : '');
  const [applyOnSubdomains, setApplyOnSubdomains] = useState<boolean>(true);
  const [tabValue, setTabValue] = React.useState(0);
  const [working, setWorking] = useState<boolean>(false);
  const [alert, setAlert] = React.useState(false);
  const [alertText, setAlertText] = React.useState('');
  const [savedMessage, setSavedMessage] = React.useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);


  useEffect(() => {
     if (urlInput !== undefined)
     {
        loadData();
     }
    
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const changeURL = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setUrlInput(event.target.value)
  }

  //ZMENA actionParameters pole
  //****************************
  const changeAcionPrameters = (event: { target: { value: string; id: string }; }) => {
    var params = event.target.id.split('_'); //pattern actionparameters_type:none_id:1
    if (params[1] === 'none')
      setFormDataNone(formDataNone.map(item => {
        if (item.id.toString() === params[2])
          return { ...item, actionParameters: event.target.value };
        else
          return item;
      }));
    if (params[1] === 'balanced')
      setFormDataBalanced(formDataBalanced.map(item => {
      if (item.id.toString() === params[2])
        return { ...item, actionParameters: event.target.value };
      else
        return item;
    }));
    if (params[1] === 'all')
    setFormDataAll(formDataAll.map(item => {
      if (item.id.toString() === params[2])
        return { ...item, actionParameters: event.target.value };
      else
        return item;
    }));
  }

  
  //ZMENA actionType pole
  //****************************
  const changeActionType = (event: { target: { value: string; name:string }; }) => {
    var params = event.target.name.split('_'); //pattern actiontype_type:none_id:1
    console.log(event.target.name);
    console.log(params);
    console.log(event);
    if (params[1] === 'none')
      setFormDataNone(formDataNone.map(item => {
        if (item.id.toString() === params[2])
          return { ...item, actionType: event.target.value, actionParameterArray: item.actionParameterArray !== undefined ? item.actionParameterArray : {key:"value"} };
        else
          return item;
      }));
    if (params[1] === 'balanced')
      setFormDataBalanced(formDataBalanced.map(item => {
      if (item.id.toString() === params[2])
        return { ...item, actionType: event.target.value, actionParameterArray: item.actionParameterArray !== undefined ? item.actionParameterArray : {key:"value"}};
      else
        return item;
    }));
    if (params[1] === 'all')
      setFormDataAll(formDataAll.map(item => {
      if (item.id.toString() === params[2])
        return { ...item, actionType: event.target.value, actionParameterArray: item.actionParameterArray !== undefined ? item.actionParameterArray : {key:"value"} };
      else
        return item;
    }));
  }
  //HANDLER BUTTON LOAN
  //*******************
  const buttonLoadHandler = async() => {
    loadData();
  }

  //TLACICKO LOAD
  //*************
  const loadData = async() => {
    console.log('loading data url:' + urlInput)
    if (urlInput === '') return;
    setWorking(true);
    const token = await getAccessTokenSilently();

    const response = await fetch(process.env.REACT_APP_ACTIONS_URL +"?url="+urlInput, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    if (response) {
        const actions = await response.json();
        setApplyOnSubdomains(actions.applyOnSubdomains)
        setFormDataNone(actions.stepsNone !== undefined ? actions.stepsNone : []);
        setFormDataBalanced(actions.stepsBalanced !== undefined ? actions.stepsBalanced : []);
        setFormDataAll(actions.stepsAll !== undefined ? actions.stepsAll : []);
        console.log("data loaded.")
      }
    setWorking(false);
  };

  //TLACITKO ADD BUTTON NONE
  //************************
  const handleAddFieldNone = () => {
    if (formDataNone === undefined)
      setFormDataNone(initialFormData);
    else
      setFormDataNone(prevState => [
        ...prevState,
        { id: prevState.length + 1, actionType: 'hide', actionParameters: '', actionParameterArray: { key:"value" }   },
      ]);
  };

  //TLACITKO ADD BUTTON BALLANCED
  //*****************************
  const handleAddFieldBalanced = () => {
    if (formDataBalanced === undefined)
    setFormDataBalanced(initialFormData);
    else
      setFormDataBalanced(prevState => [
        ...prevState,
        { id: prevState.length + 1, actionType: 'hide', actionParameters: '', actionParameterArray: { key:"value" }   },
      ]);
  };

  //TLACITKO ADD BUTTON ALL
  //***********************
  const handleAddFieldAll = () => {
    if (formDataAll === undefined)
      setFormDataAll(initialFormData);
    else
      setFormDataAll(prevState => [
        ...prevState,
        { id: prevState.length + 1, actionType: 'hide', actionParameters: '', actionParameterArray: { key:"value" }   },
      ]);
  };

  //TLACITKO DELETE ACTION ROW
  //*******************************
  const actionRowDelete = (preference: string, id: number) => {
    if (preference === 'none')
      {
        var arrNone = formDataNone.filter((item) => item.id !== id);
        setFormDataNone(arrNone.map(item => {
          if (item.id > id-1)
          {
             return { ...item, id: item.id -1 };
          }
          else return item;
        }));
        return;
      }
    if (preference === 'balanced')
    {
      var arrBalanced = formDataBalanced.filter((item) => item.id !== id);
      setFormDataBalanced(arrBalanced.map(item => {
        if (item.id > id-1)
        {
           return { ...item, id: item.id -1 };
        }
        else return item;
      }));
      return;
    }
    if (preference === 'all')
    {
      var arrAll = formDataAll.filter((item) => item.id !== id);
      setFormDataAll(arrAll.map(item => {
        if (item.id > id-1)
        {
           return { ...item, id: item.id -1 };
        }
        else return item;
      }));
    }
  };
  
  //TLACITKO ACTION ARRRAY ADD
  //**************************
  const actionArrayAdd = (preference: string, id: number) => {
    if (preference === 'none')
    {
          console.log(id);
          setFormDataNone(formDataNone.map(item => {
          if (item.id === id)
          {
            var appArray = { ...item.actionParameterArray, key:'value'};
            item.actionParameterArray = appArray;
            console.log(appArray);
            return item;
          }
          else
            return item;
        }));
    }
    if (preference === 'balanced')
    {
          console.log(id);
          setFormDataNone(formDataBalanced.map(item => {
          if (item.id === id)
          {
            var appArray = { ...item.actionParameterArray, key:'value'};
            item.actionParameterArray = appArray;
            console.log(appArray);
            return item;
          }
          else
            return item;
        }));
    }
    if (preference === 'all')
    {
          console.log(id);
          setFormDataNone(formDataAll.map(item => {
          if (item.id === id)
          {
            var appArray = { ...item.actionParameterArray, key:'value'};
            item.actionParameterArray = appArray;
            console.log(appArray);
            return item;
          }
          else
            return item;
        }));
    }
  }

    //TLACITKO DELETE ACTION PARAMETER ARRAY
  //****************************************
  const actionParameterArrayDelete = (preference: string, id: number, key: string) => {
    if (preference === 'none')
      {
        setFormDataNone(formDataNone.map(item => {
          if (item.id === id)
          {
             if (item.actionParameterArray !== undefined)
             { 
              delete item.actionParameterArray[key];
             }
             return item;
          }
          else return item;
        }));
        return;
      }
    if (preference === 'balanced')
    {
      setFormDataBalanced(formDataBalanced.map(item => {
        if (item.id === id)
        {
           if (item.actionParameterArray !== undefined)
           { 
            delete item.actionParameterArray[key];
           }
           return item;
        }
        else return item;
    }));
      return;
    }
    if (preference === 'all')
    {
      setFormDataAll(formDataAll.map(item => {
        if (item.id === id)
        {
           if (item.actionParameterArray !== undefined)
           { 
            delete item.actionParameterArray[key];
           }
           return item;
        }
        else return item;
    }));
    }
  };

  //ZMENA ACTION ARRRAY TEXT BOX = KEY
  //**************************
  const changeAcionPrameterArrayKey = (event: { target: { value: string; id: string }; }) => {
    var params = event.target.id.split('_'); //pattern actionparameterArray_type:none_id:1_key
    if (params[1] === 'none')
    {
          setFormDataNone(formDataNone.map(item => {
          if (item.id.toString() === params[2])
          {
            if (item.actionParameterArray !== undefined)
            {
              const oldValue = item.actionParameterArray[params[3]];
              const position = Object.keys(item.actionParameterArray).indexOf(params[3]);
              const newKey = event.target.value;
              const updatedRecord = {
                ...Object.fromEntries(
                  Object.entries(item.actionParameterArray).slice(0, position)
                ),
                [newKey]: oldValue,
                ...Object.fromEntries(
                  Object.entries(item.actionParameterArray).slice(position+1)
                )
              };
              item.actionParameterArray = updatedRecord;
              return item;
            }
            return item;
          }
          else
            return item;
        }));
    }
    if (params[1] === 'balanced')
    {
          setFormDataBalanced(formDataBalanced.map(item => {
          if (item.id.toString() === params[2])
          {
            if (item.actionParameterArray !== undefined)
            {
              const oldValue = item.actionParameterArray[params[3]];
              const position = Object.keys(item.actionParameterArray).indexOf(params[3]);
              const newKey = event.target.value;
              const updatedRecord = {
                ...Object.fromEntries(
                  Object.entries(item.actionParameterArray).slice(0, position)
                ),
                [newKey]: oldValue,
                ...Object.fromEntries(
                  Object.entries(item.actionParameterArray).slice(position+1)
                )
              };
              item.actionParameterArray = updatedRecord;
              return item;
            }
            return item;
          }
          else
            return item;
        }));
    }
    if (params[1] === 'all')
    {
          setFormDataAll(formDataAll.map(item => {
          if (item.id.toString() === params[2])
          {
            if (item.actionParameterArray !== undefined)
            {
              const oldValue = item.actionParameterArray[params[3]];
              const position = Object.keys(item.actionParameterArray).indexOf(params[3]);
              const newKey = event.target.value;
              const updatedRecord = {
                ...Object.fromEntries(
                  Object.entries(item.actionParameterArray).slice(0, position)
                ),
                [newKey]: oldValue,
                ...Object.fromEntries(
                  Object.entries(item.actionParameterArray).slice(position+1)
                )
              };
              item.actionParameterArray = updatedRecord;
              return item;
            }
            return item;
          }
          else
            return item;
        }));
    }
  }


  //ZMENA ACTION ARRRAY TEXT BOX = VALUE
  //**************************
  const changeAcionPrameterArrayValue = (event: { target: { value: string; id: string }; }) => {
    var params = event.target.id.split('_'); //pattern actionparameterArray_type:none_id:1_key
    if (params[1] === 'none')
    {
          setFormDataNone(formDataNone.map(item => {
          if (item.id.toString() === params[2])
          {
            var appArray = item.actionParameterArray;
            if (appArray !== undefined) appArray[params[3]] = event.target.value;
            item.actionParameterArray = appArray;
            return item;
          }
          else
            return item;
        }));
    }
    if (params[1] === 'balanced')
    {
          setFormDataBalanced(formDataBalanced.map(item => {
          if (item.id.toString() === params[2])
          {
            var appArray = item.actionParameterArray;
            if (appArray !== undefined) appArray[params[3]] = event.target.value;
            item.actionParameterArray = appArray;
            return item;
          }
          else
            return item;
        }));
    }
    if (params[1] === 'all')
    {
          setFormDataAll(formDataAll.map(item => {
          if (item.id.toString() === params[2])
          {
            var appArray = item.actionParameterArray;
            if (appArray !== undefined) appArray[params[3]] = event.target.value;
            item.actionParameterArray = appArray;
            return item;
          }
          else
            return item;
        }));
    }
  }

  //ZMENA TABU
  //**********
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const savedMessageClose = () => {
    setSavedMessage(false);
  }

  //SAVE BUTTON
  //***********
  const handleSave = async() =>{
    var alertText = '';
    if (urlInput === '')
    {
      alertText += 'URL is empty. Enter url for new item, or click Load for load existing item.\n';
    }
    formDataNone.forEach(element => {
      if (actionParametersType.has(element.actionType) && element.actionParameters === '')
        alertText += 'ActionParameters field is empty for action id: '+ element.id + ' on None tab.\n'
        if (actionParameterArrayType.has(element.actionType))
        { 
          if (element.actionParameterArray === undefined)
            alertText += 'ActionParameterArray field is empty for action id: '+ element.id + ' on None tab.\n';
          else
          {
            if (Object.values(element.actionParameterArray).filter((value) => value ==='').length > 0)
              alertText += 'ActionParameterArray has empty value in one item in action id: '+ element.id + ' on None tab.\n';
              if (Object.keys(element.actionParameterArray).filter((key) => key ==='').length > 0)
              alertText += 'ActionParameterArray has empty key in one item in action id: '+ element.id + ' on None tab.\n';
          }
        }
    });
    formDataBalanced.forEach(element => {
        if (actionParametersType.has(element.actionType) && element.actionParameters === '')
          alertText += 'ActionParameters field is empty for action id: '+ element.id + ' on Balanced tab.\n'
        if (actionParameterArrayType.has(element.actionType))
        {
          if (element.actionParameterArray === undefined)
            alertText += 'ActionParameterArray field is empty for action id: '+ element.id + ' on Balanced tab.\n';
          else
          {
            if (Object.values(element.actionParameterArray).filter((value) => value ==='').length > 0)
              alertText += 'ActionParameterArray has empty value in one item in action id: '+ element.id + ' on Balanced tab.\n';
            if (Object.keys(element.actionParameterArray).filter((key) => key ==='').length > 0)
              alertText += 'ActionParameterArray has empty key in one item in action id: '+ element.id + ' on Balanced tab.\n';
          }
        } 
    });
    formDataAll.forEach(element => {
      if (actionParametersType.has(element.actionType) && element.actionParameters === '')
        alertText += 'ActionParameters field is empty for action id: '+ element.id + ' on All tab.\n'
      if (actionParameterArrayType.has(element.actionType))
      {
        if(element.actionParameterArray === undefined)
          alertText += 'ActionParameterArray field is empty for action id: '+ element.id + ' on All tab.\n'
          else
          {
            if (Object.values(element.actionParameterArray).filter((value) => value ==='').length > 0)
              alertText += 'ActionParameterArray has empty value in one item in action id: '+ element.id + ' on All tab.\n';
            if (Object.keys(element.actionParameterArray).filter((key) => key ==='').length > 0)
              alertText += 'ActionParameterArray has empty key in one item in action id: '+ element.id + ' on All tab.\n';
          }
      }
    });

    if (alertText !== '')
    {
      setAlertText(alertText);
      setAlert(true);
      return;
    }
    setAlert(false);
    setWorking(true);

    const saveData = {
      url: urlInput,
      applyOnSubdomains: applyOnSubdomains,
      stepsNone: formDataNone,
      stepsBalanced: formDataBalanced,
      stepsAll: formDataAll
    }
    console.log(saveData);

    const token = await getAccessTokenSilently();

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',
                  'Authorization': `Bearer ` + token },
      body: JSON.stringify(saveData)
      };
    await fetch(process.env.REACT_APP_ACTIONS_URL, requestOptions);
    setWorking(false);
    setSavedMessage(true);
  }

  //DELETE BUTTON
  //***********
  const handleDelete = async() =>{
    var alertText = '';
    if (urlInput === '')
    {
      alertText += 'URL is empty. Enter url for new item, or click Load for load existing item.\n';
    }


    if (alertText !== '')
    {
      setAlertText(alertText);
      setAlert(true);
      return;
    }
    setAlert(false);
    setWorking(true);

    const token = await getAccessTokenSilently();

    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json',
                  'Authorization': `Bearer ` + token }
      };
    await fetch(process.env.REACT_APP_ACTION_URL+'?url='+urlInput, requestOptions);
    setWorking(false);
    setSavedMessage(true);
    
    loadData();
  }

  // URL TEXTBOX KEY PRESS HANDLER = ENTER
  //**************************************
  const handleEnterInUrl = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // prevent form submission
      buttonLoadHandler();
    }
  };


  // KOPIE DO OSTATNICH TABU
  const copyToOtherTabs = (src: string) => {
    if (src === 'none')
    {
      setFormDataBalanced(formDataNone);
      setFormDataAll(formDataNone);
    }
    if (src ==='balanced')
    {
      setFormDataNone(formDataBalanced);
      setFormDataAll(formDataBalanced);
    }
    if (src === 'all')
    {
      setFormDataNone(formDataAll);
      setFormDataBalanced(formDataAll);
    }
  };

  return (
    <>
      <Box
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '1000' },
            }}
            noValidate
            autoComplete="off"
          >
                <div  style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <TextField label="URL" id="url" required value={urlInput} onChange={changeURL} onKeyDown={handleEnterInUrl} inputProps={{ style: { width: "600px" }}}
                   />
                <Button variant="contained" onClick={buttonLoadHandler}>Load</Button>
            </div> 
            <Box sx={{ width: '100%' }}>
                { working ? 
                  <LinearProgress key='waitingStatus'  />
                : null}
            </Box>
        </Box>

        <FormControlLabel control={<Checkbox checked={applyOnSubdomains} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setApplyOnSubdomains(event.target.checked)} 
                />} label="Apply on subdomains" sx={{pl:1}} />

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="None" />
              <Tab label="Balanced"  />
              <Tab label="All" />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            {
              formDataNone !== undefined ?
                formDataNone.map(field => (
                  <Card sx={{ minWidth: 275, padding:2, borderRadius: 2, m:2 }} className="d-grid gap-3" key={'cardTopNone'+field.id}>
                    <React.Fragment key={field.id}>
                        <IconButton
                                aria-label="close"
                                color="inherit"
                                size="medium"
                                onClick={() => actionRowDelete('none', field.id)}
                              >
                                <DeleteIcon fontSize="inherit" />
                        </IconButton>
                        <span>Action id: {field.id}</span><br></br>
                        <Grid container spacing={1} >
                          <Grid container item xs={3} direction="column">
                            <div  style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                            <span>Action type: </span>
                            <FormControl sx={{ m: 1, width: 300 }}>
                            <Select id={'actionType_none_'+field.id} name={'actionType_none_'+field.id} label="Action type" sx={{minWith:300}} value={field.actionType} onChange={changeActionType}>
                                <MenuItem key='click' value='click'>Click</MenuItem>
                                <MenuItem key='hide' value='hide'>Hide</MenuItem>
                                <MenuItem key='cookieDelete' value='cookieDelete'>Cookie delete</MenuItem>
                                <MenuItem key='cookieSet' value='cookieSet'>Cookie set</MenuItem>
                                <MenuItem key='removeCSS' value='removeCSS'>Remove CSS</MenuItem>
                                <MenuItem key='setCSS' value='setCSS'>Set CSS</MenuItem>¨
                                <MenuItem key='nonAutoDelete' value='nonAutoDelete'>Non Automatic delete</MenuItem>
                            </Select>
                            </FormControl></div>
                          </Grid>
                          <Grid container item xs={9} direction="row">
                            {(actionParametersType.has(field.actionType)) ? 
                                <TextField id={'actionParameters_none_'+field.id} value={field.actionParameters} label="Action parameters" sx={{ m: 1, width: 800 }} onChange={changeAcionPrameters}
                                /> : null }
                            {(actionParameterArrayType.has(field.actionType)) ? 
                                (field.actionParameterArray === undefined ? <TextField id='noneCookie1' value='' label="Action parameters array" inputProps={{ inputMode: 'text', pattern: '[a-zA-Z!]+.[a-zA-Z!]+:[a-zA-Z,!]+' }}></TextField> :
                                Object.entries(field.actionParameterArray).map(([key, value])  => (
                                  <Grid container spacing={2} xs={14} padding={2} direction="row">
                                    <Grid item xs={3}>
                                        <TextField id={'actionParameterArrayKey_none_'+field.id+'_'+key} value={key} label="Action parameters array - key" inputProps={{ inputMode: 'text', pattern: '[a-zA-Z!]+.[a-zA-Z!]+:[a-zA-Z,!]+' }}  onChange={changeAcionPrameterArrayKey} sx={{width:250}}> </TextField>
                                      </Grid>
                                    <Grid item xs={7}>
                                        <TextField id={'actionParameterArrayValue_none_'+field.id+'_'+key} value={value} label="Action parameters array - value" inputProps={{ inputMode: 'text', pattern: '[a-zA-Z!]+.[a-zA-Z!]+:[a-zA-Z,!]+' }} onChange={changeAcionPrameterArrayValue} sx={{width:680}}> </TextField>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="medium"
                                              onClick={() => actionParameterArrayDelete('none', field.id, key)}
                                            >
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                    </Grid>                                  
                                  </Grid>
                                )))
                                : null }
                            {(actionParameterArrayType.has(field.actionType)) ? 
                                <Button
                                    onClick={() => actionArrayAdd('none', field.id)}
                                    >Add action array</Button>
                                : null }
                          </Grid>
                        </Grid>

                    </React.Fragment>
                  </Card>
            )) : null
            }
            <Button variant="contained" onClick={handleAddFieldNone}>Add action</Button>
            <Button variant="contained" onClick={() => copyToOtherTabs('none')}>Copy to other tabs</Button>
            
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {
              formDataBalanced !== undefined ?
                formDataBalanced.map(field => (
                  <Card sx={{ minWidth: 275, padding:2,           borderRadius: 2, m:2 }} className="d-grid gap-3" key={'cardTopBalanced'+field.id}>
                    <React.Fragment key={field.id}>
                        <IconButton
                                aria-label="close"
                                color="inherit"
                                size="medium"
                                onClick={() => actionRowDelete('balanced', field.id)}
                              >
                                <DeleteIcon fontSize="inherit" />
                        </IconButton>
                        <span>Action id: {field.id}</span><br></br>
                        <Grid container spacing={1} >
                          <Grid container item xs={3} direction="column" >
                            <div  style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                            <span>Action type: </span>
                            <FormControl sx={{ m: 1, width: 300 }}>
                            <Select id={'actionType_balanced_'+field.id} name={'actionType_balanced_'+field.id} label="Action type" sx={{minWith:300}} value={field.actionType} onChange={changeActionType}>
                                <MenuItem key='click' value='click'>Click</MenuItem>
                                <MenuItem key='hide' value='hide'>Hide</MenuItem>
                                <MenuItem key='cookieDelete' value='cookieDelete'>Cookie delete</MenuItem>
                                <MenuItem key='cookieSet' value='cookieSet'>Cookie set</MenuItem>
                                <MenuItem key='removeCSS' value='removeCSS'>Remove CSS</MenuItem>
                                <MenuItem key='setCSS' value='setCSS'>Set CSS</MenuItem>
                                <MenuItem key='nonAutoDelete' value='nonAutoDelete'>Non Automatic delete</MenuItem>
                            </Select>
                            </FormControl></div>
                          </Grid>
                          <Grid container item xs={9} direction="row" >
                            {(actionParametersType.has(field.actionType)) ? 
                                <TextField id={'actionParameters_balanced_'+field.id} value={field.actionParameters} label="Action parameters" sx={{ m: 1, width: 800 }} onChange={changeAcionPrameters}
                                /> : null }
                            {(actionParameterArrayType.has(field.actionType)) ? 
                                (field.actionParameterArray === undefined ? <TextField id='balancedCookie1' value='' label="Action parameters array" inputProps={{ inputMode: 'text', pattern: '[a-zA-Z!]+.[a-zA-Z!]+:[a-zA-Z,!]+' }}></TextField> :
                                Object.entries(field.actionParameterArray).map(([key, value])  => (
                                  <Grid container spacing={2} xs={14} padding={2}>
                                    <Grid item xs={3}>
                                        <TextField id={'actionParameterArrayKey_balanced_'+field.id+'_'+key} value={key} label="Action parameters array - key" inputProps={{ inputMode: 'text', pattern: '[a-zA-Z!]+.[a-zA-Z!]+:[a-zA-Z,!]+' }}  onChange={changeAcionPrameterArrayKey} sx={{width:250}}> </TextField>
                                      </Grid>
                                    <Grid item xs={7}>
                                        <TextField id={'actionParameterArrayValue_balanced_'+field.id+'_'+key} value={value} label="Action parameters array - value" inputProps={{ inputMode: 'text', pattern: '[a-zA-Z!]+.[a-zA-Z!]+:[a-zA-Z,!]+' }} onChange={changeAcionPrameterArrayValue} sx={{width:680}}> </TextField>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="medium"
                                              onClick={() => actionParameterArrayDelete('balanced', field.id, key)}
                                            >
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                    </Grid>  
                                  </Grid>
                                )))
                                : null }
                            {(actionParameterArrayType.has(field.actionType)) ? 
                                <Button
                                    onClick={() => actionArrayAdd('balanced', field.id)}
                                    >Add action array</Button>
                                : null }
                          </Grid>
                        </Grid>
                    </React.Fragment>
                  </Card>
                ))
              : null
            }
            <Button variant="contained" onClick={handleAddFieldBalanced}>Add action</Button>
            <Button variant="contained" onClick={() => copyToOtherTabs('balanced')}>Copy to other tabs</Button>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            {
              formDataAll !== undefined ?
                formDataAll.map(field => (
                  <Card sx={{ minWidth: 275, padding:2,           borderRadius: 2, m:2 }} className="d-grid gap-3" key={'cardTopAll'+field.id}>
                    <React.Fragment key={field.id}>
                        <IconButton
                                aria-label="close"
                                color="inherit"
                                size="medium"
                                onClick={() => actionRowDelete('all', field.id)}
                              >
                                <DeleteIcon fontSize="inherit" />
                        </IconButton>
                        <span>Action id: {field.id}</span><br></br>
                        <Grid container spacing={1} >
                          <Grid container item xs={3} direction="column" >
                            <div  style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                            <span>Action type: </span>
                            <FormControl sx={{ m: 1, width: 300 }}>
                            <Select id={'actionType_all_'+field.id} name={'actionType_all_'+field.id} label="Action type" sx={{minWith:300}} value={field.actionType} onChange={changeActionType}>
                                <MenuItem key='click' value='click'>Click</MenuItem>
                                <MenuItem key='hide' value='hide'>Hide</MenuItem>
                                <MenuItem key='cookieDelete' value='cookieDelete'>Cookie delete</MenuItem>
                                <MenuItem key='cookieSet' value='cookieSet'>Cookie set</MenuItem>
                                <MenuItem key='removeCSS' value='removeCSS'>Remove CSS</MenuItem>
                                <MenuItem key='setCSS' value='setCSS'>Set CSS</MenuItem>
                                <MenuItem key='nonAutoDelete' value='nonAutoDelete'>Non Automatic delete</MenuItem>
                            </Select>
                            </FormControl></div>
                          </Grid>
                          <Grid container item xs={9} direction="row" >
                            {(actionParametersType.has(field.actionType)) ? 
                                <TextField id={'actionParameters_all_'+field.id} value={field.actionParameters} label="Action parameters" sx={{ m: 1, width: 800 }} onChange={changeAcionPrameters}
                                /> : null }
                            {(actionParameterArrayType.has(field.actionType)) ? 
                                (field.actionParameterArray === undefined ? <TextField id='allCookie1' value='' label="Action parameters array" inputProps={{ inputMode: 'text', pattern: '[a-zA-Z!]+.[a-zA-Z!]+:[a-zA-Z,!]+' }}></TextField> :
                                Object.entries(field.actionParameterArray).map(([key, value])  => (
                                  <Grid container spacing={2} xs={14} padding={2}>
                                    <Grid item xs={3}>
                                        <TextField id={'actionParameterArrayKey_all_'+field.id+'_'+key} value={key} label="Action parameters array - key" inputProps={{ inputMode: 'text', pattern: '[a-zA-Z!]+.[a-zA-Z!]+:[a-zA-Z,!]+' }}  onChange={changeAcionPrameterArrayKey} sx={{width:250}}> </TextField>
                                      </Grid>
                                    <Grid item xs={7}>
                                        <TextField id={'actionParameterArrayValue_all_'+field.id+'_'+key} value={value} label="Action parameters array - value" inputProps={{ inputMode: 'text', pattern: '[a-zA-Z!]+.[a-zA-Z!]+:[a-zA-Z,!]+' }} onChange={changeAcionPrameterArrayValue} sx={{width:680}}> </TextField>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                              aria-label="close"
                                              color="inherit"
                                              size="medium"
                                              onClick={() => actionParameterArrayDelete('all', field.id, key)}
                                            >
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                    </Grid>  
                                  </Grid>
                                )))
                                : null }
                            {(actionParameterArrayType.has(field.actionType)) ? 
                                <Button
                                    onClick={() => actionArrayAdd('all', field.id)}
                                    >Add action array</Button>
                                : null }
                          </Grid>
                        </Grid>
                    </React.Fragment>
                  </Card>
                ))
              : null
            }
            <Button variant="contained" onClick={handleAddFieldAll}>Add action</Button>
            <Button variant="contained" onClick={() => copyToOtherTabs('all')}>Copy to other tabs</Button>

          </TabPanel>
        </Box>
      
      <Collapse in={alert}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2, whiteSpace: 'pre-line' }}
        >
          {alertText}
        </Alert>
      </Collapse>
      <Button variant="contained" color="primary" type="submit" sx={{m:2}} onClick={handleSave}>Save</Button>
      <Button  color="primary" type="submit" sx={{m:2}} onClick={ () => { if (urlInput!=="") { setIsConfirmDialogOpen(true) } } }>Delete</Button>

      <Snackbar
        open={savedMessage}
        autoHideDuration={3000}
        onClose={savedMessageClose}
      >
          <Alert severity="success" sx={{ width: '100%' }}>
            Data saved.
          </Alert>
      </Snackbar>
      
      <ConfirmDialog
        open={isConfirmDialogOpen}
        title="Confirmation"
        message="Are you sure you want to delete this item?"
        onConfirm={handleDelete}
        onCancel={() => { setIsConfirmDialogOpen(false)}}
      />

    </>);
};

export default DynamicForm;
