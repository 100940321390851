import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAuth0 } from "@auth0/auth0-react";
import Dialog  from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import DisplaySettingsIcon from '@mui/icons-material/Edit';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import moment from "moment";
import ConfirmDialog from '../components/ConfirmDialog';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import SearchIcon from '@mui/icons-material/Search';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Language from '@mui/icons-material/Language';
import Add from '@mui/icons-material/Add';
import Email from '@mui/icons-material/Email';
import {Link} from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import WebDetailSelectDialog from '../components/WebDetailSelectDialog';
import Papa from 'papaparse'

export default function DataGridDemo() {

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Actions",
      width:50,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleOpen(params.row)}>
            <DisplaySettingsIcon />
          </IconButton>
        );
      }
    },
    { field: 'id', headerName: 'id', width: 140},
    { field: 'regNum', headerName: 'regNum', width: 200},
    { field: 'name', headerName: 'name', width: 300},
    { field: 'changedUsername', headerName: 'Changed by', width: 320 },
    { field: 'changed', headerName: 'Changed', width: 220,   type: 'dateTime',  valueFormatter: params => params?.value ? moment(params?.value).format("DD.MM.YYYY HH:mm:ss"):"" },

  ];
  
  const columnsWebs: GridColDef[] = [
    { field: 'url', headerName: 'URL', width: 600,
    renderCell: (params) => (
      <><a href={`https://${params.value}`} target='_blank' rel="noreferrer">{params.value}</a><Link to={`/actions/${params.value}`} style={{padding: '30px'}}
      >
        <Button>Edit Actions</Button>
      </Link></>
    )
    },
    {
      field: "actions",
      headerName: "Actions",
      width:50,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => deleteWebDetailFromCompany(params.row.url)}>
            <DeleteIcon />
          </IconButton>
        );
      }
    },
  ];
   

    const [open, setOpen] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [openLink, setOpenLink] = useState(false);
    const [loading, setLoading] = useState(false);


    const { getAccessTokenSilently } = useAuth0();
    const [tableData, setTableData] = useState([]);

    const [name, setName] = useState('');
    const [regNum, setRegNum] = useState('');

    const [item, setItem] = useState(Object);

    const [tabValue, setTabValue] = React.useState('1');
    const [notFoundCompany, setNotFoundCompany] = React.useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

    const [codebookEmailType, setCodebookEmailType] = useState<string[]>([]);
    const [codebookLinkType, setCodebookLinkType] = useState<string[]>([]);

    const [newEmail, setNewEmail] = useState('');
    const [newEmailType, setNewEmailType] = useState('');
    const [newLink, setNewLink] = useState('');
    const [newLinkType, setNewLinkType] = useState('');


    const changeHandler = (event:any) => {
      // Passing file data (event.target.files[0]) to parse using Papa.parse
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results:any) {
          results.data.forEach((element:any) => {
            if (element.ico !== "")
            {
              console.log(element)
              const importCompany = { regNum: element.ICO, url: element.url, linkPrivacyNotice:element.Odkaz_Prohlaseni_zpracovani_osobnich_udaju, 
                    linkCookieNotice:element.Odkaz_Cookies, emailDPO:element.Email_na_DPO}
              ImportCompany(importCompany)
            }
          });
        },
      });
    };

    //SAVE COMPANY DIALOG
    const ImportCompany = async(importCompany:any) => {
  
      console.log(importCompany);

      const token = await getAccessTokenSilently();
  
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + token },
        body: JSON.stringify(importCompany)
        };
      await fetch(process.env.REACT_APP_COMPANY_URL+'/import', requestOptions);
      
    }

    //close of not found company notification
    const notFoundCompanyClose = () => {
      setNotFoundCompany(false);
    }

    //extension of regnum text field with search button
    const SearchButton = () => (
      <IconButton onClick={searchMerkCompany}>
        <SearchIcon />
      </IconButton>
      )

    //HANDLE TAB CHANGE ON PARTNER DETAIL DIALOG
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setTabValue(newValue);
    };

    //PRESS ENTER IN SEARCH TEXT BOX
    const handleEnterInUrl = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault(); // prevent form submission
        loadData();
      }
    };

    //SEARCH REGNUM IN MERK COMPANY DATABASE
    const searchMerkCompany = async() => {
      const token = await getAccessTokenSilently();

      const response = await fetch(process.env.REACT_APP_MERKCOMPANY_URL +"?regnum="+item.regNum, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status && response.status === 200) {
          const merkcompany = await response.json();
          if (merkcompany != null)
          {
            setItem((prevState:any) => ({
              ...prevState,
                vat: merkcompany.vatno,
                name: merkcompany.name,
                databox: merkcompany.databox_ids ? merkcompany.databox_ids.join(','):'',
                street: merkcompany.address.street,
                streetNumber: merkcompany.address.number,
                city: merkcompany.address.municipality,
                postCode: merkcompany.address.postal_code,
                country: merkcompany.address.country_code
              }))
            console.log("data loaded.")
          }
          else
            setNotFoundCompany(true);
        }
        else
        setNotFoundCompany(true);

    }
    //LOAD DATA TO COMPANIES LIST
    // eslint-disable-next-line
    const loadData = async() => {

      console.log("button clicked ");
      setLoading(true);

      const token = await getAccessTokenSilently();

      const response = await fetch(process.env.REACT_APP_COMPANIES_URL +"?regNum="+regNum+"&name="+name, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.status) {
          const companies = await response.json();
          if (companies != null)
            setTableData(companies);
          console.log("data loaded.")
        }
        setLoading(false);
  };

    //LOAD CODEBOOKS = PURPOSES AND CATEGORES
  const loadCodebooks = async() => {

    const token = await getAccessTokenSilently();

    const response = await fetch(process.env.REACT_APP_CODEBOOK_URL +"?codebookId=C005", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    if (response.status) {
        const codebook = await response.json();
        if (codebook != null)
        {
          var array:any[] = [];
          codebook.items.filter((item:any) => item.active===true).forEach((item:any) => {
            array.push({value:item.code, label:item.name})
          });
          setCodebookEmailType(array);
        }
        console.log("data loaded.")
        console.log(codebookEmailType)
      }
    const response2 = await fetch(process.env.REACT_APP_CODEBOOK_URL +"?codebookId=C006", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    if (response2.status) {
        const codebook2 = await response2.json();
        if (codebook2 != null)
        {
          var array2:any[] = [];
          codebook2.items.filter((item:any) => item.active===true).forEach((item:any) => {
            array2.push({value:item.code, label:item.name})
          });
          setCodebookLinkType(array2);
        }
        console.log("data loaded.")
      }

};

    //AUTO LOAD AFTER DIALOG INITIALIZATION
    useEffect(() => {
      loadData();
      loadCodebooks();
    // eslint-disable-next-line
    }, [])

        // OPEN COMPANY DIALOG
        const handleOpen = async(props:any) => {

          console.log("company open "+ props.id);

          //DONT LOAD NEW RECORD
          if (props.id === 'new')
          {
            setItem(props)
            setOpen(true);
            return
          } 

          setLoading(true);
    
          const token = await getAccessTokenSilently();
    
          const response = await fetch(process.env.REACT_APP_COMPANY_URL +"/"+props.id, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          if (response.status) {
              const company = await response.json();
              if (company != null)
                setItem(company);
              console.log("data loaded.")
            }

          setLoading(false);
          setOpen(true);
        };
    
        //CLOSE COMPANY DIALOG
        const handleClose = () => {
          setOpen(false);
        };

        //CLOSE EMAIL DIALOG
        const handleEmailClose = () => {
          setOpenEmail(false);
        };

                
        //CLOSE LINK DIALOG
        const handleLinkClose = () => {
          setOpenLink(false);
        };

        const addCompanyEmail = () => {
          setNewEmail('')
          setNewEmailType('')
          setOpenEmail(true)
        }

        const addCompanyLink = () => {
          setNewLink('')
          setNewLinkType('')
          setOpenLink(true)
        }

        //SAVE BUTTON IN NEW EMAIL DIALOG
        const handleSaveNewEmail = () => {
          const emailTypeLabel = (codebookEmailType.find((item:any) => item.value === newEmailType) as any).label;
          item.emails.push({email:newEmail, emailType: newEmailType , emailTypeLabel:emailTypeLabel })
          setOpenEmail(false)
        }

        //SAVE BUTTON IN NEW LINK DIALOG
        const handleSaveNewLink = () => {
          const linkTypeLabel = (codebookLinkType.find((item:any) => item.value === newLinkType) as any).label;
          item.links.push({link:newLink, linkType: newLinkType, linkTypeLabel: linkTypeLabel})
          setOpenLink(false)
        }

        //DELETE EMAIL IN COMPANY
        const deleteEmail = (emailToDelete:string) => {
          const removedArray = item.emails.filter((obj:any) => obj.email !== emailToDelete);
          setItem((prevState:any) => ({
            ...prevState,
              emails: removedArray
          }))
      }
      
        //DELETE LINK IN COMPANY
        const deleteLink = (linkToDelete:string) => {
            const removedArray = item.links.filter((obj:any) => obj.link !== linkToDelete);
            setItem((prevState:any) => ({
              ...prevState,
                links: removedArray
            }))
        }
        

        //SAVE COMPANY DIALOG
        const handleSave = async() => {
          
          const itemToSave = item;

          console.log(itemToSave);
    
          const token = await getAccessTokenSilently();
      
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + token },
            body: JSON.stringify(itemToSave)
            };
          await fetch(process.env.REACT_APP_COMPANY_URL, requestOptions);
          
          loadData();
          setOpen(false);
        }


        //DELETE COMPANY
        const handleDelete = async() => {
           
          const token = await getAccessTokenSilently();
      
          const requestOptions = {
            method: 'DELETE',
            headers: { 
                        'Authorization': `Bearer ` + token }
            };
          await fetch(process.env.REACT_APP_COOKIE_URL + '?id=' + item.id, requestOptions);
          
          loadData();
          setOpen(false);
        }   

        //ADD EXISTING WEB DETAIL TO COMPANY
        const addWebDetail = async(url:string) => {

          console.log('Adding web: '+ url)
          const token = await getAccessTokenSilently();

          //loading web detail
          const response = await fetch(process.env.REACT_APP_WEBDETAIL_URL +"/"+url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          var webDetail:any;
          if (response.status) {
              webDetail = await response.json();
            }
          webDetail.companyId = item.id;
          //saving
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + token },
            body: JSON.stringify(webDetail)
            };
          await fetch(process.env.REACT_APP_WEBDETAIL_URL, requestOptions);

          const newWebs = [...item.webs, {url:url, id:webDetail.id }];

          setItem((prevState:any) => ({
            ...prevState,
            webs: newWebs
          }))   

        }

        //DELETE WEB DETAIL FROM COMPANY
        
        const deleteWebDetailFromCompany = async(url:string) => {

          console.log('Deleting web: '+ url)
          const token = await getAccessTokenSilently();

          //loading web detail
          const response = await fetch(process.env.REACT_APP_WEBDETAIL_URL +"/"+url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          var webDetail:any;
          if (response.status) {
              webDetail = await response.json();
            }
          webDetail.companyId = undefined;
          //saving
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + token },
            body: JSON.stringify(webDetail)
            };
          await fetch(process.env.REACT_APP_WEBDETAIL_URL, requestOptions);

          var newWebs = item.webs.filter((item:any) => item.url !== url)
          setItem((prevState:any) => ({
            ...prevState,
            webs: newWebs
          }))   

        }
  

  return (
    <Box sx={{ height: `auto`, width: '100%' }}>
      <Box
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '1000' },
            }}
            noValidate
            autoComplete="off"
          >
                <div  style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <Button variant="contained" onClick={() => handleOpen({id:'new', webs:[], otherRegNums:[]})} sx={{pr:2}}>New company</Button>
                <TextField label="Search in name" id="txtSearch" value={name} onChange={(event:any) => setName(event.target.value)} onKeyDown={handleEnterInUrl} inputProps={{ style: { width: "300px" }}} />
                <TextField label="Search in regnum" id="txtSearch" value={regNum} onChange={(event:any) => setRegNum(event.target.value)} onKeyDown={handleEnterInUrl} inputProps={{ style: { width: "300px" }}} />
                <Button variant="contained" onClick={loadData}>Search</Button>
                <input
                  type="file"
                  name="file"
                  accept=".csv"
                  onChange={changeHandler}
                  style={{ display: "block", margin: "10px auto" }}
                />
            </div> 
            <Box sx={{ width: '100%' }}>
                { loading ? 
                  <LinearProgress key='waitingStatus'  />
                : null}
            </Box>
      </Box>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={13}
        rowsPerPageOptions={[13]}
        disableSelectionOnClick
        autoHeight={true}
        experimentalFeatures={{ newEditingApi: true }}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{
          sx: {
            width: 1000,
            maxHeight: 900
          }
        }}
      >
      <DialogTitle sx={{height:90}}>Company {item.id}</DialogTitle>
        <DialogContent dividers style={{height:'900px'}}>
        <Chip
          label={'Created by: ' +item.createdUsername + ' on: ' + moment(item.created).format("DD.MM.YYYY HH:mm:ss")}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10
          }}
        ></Chip>
        <Chip
          label={'Changed by:: ' +item.changedUsername + ' on: ' + moment(item.changed).format("DD.MM.YYYY HH:mm:ss")}
          sx={{
            position: 'absolute',
            right: 8,
            top: 50
          }}></Chip>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Company" value="1" />
              <Tab label="Webs" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={2} columns={2}>
              <Grid item xs={1}>
                <TextField
                        sx={{ m: 1}}
                        label="RegNum"
                        placeholder="RegNum"
                        value={item.regNum}
                        fullWidth
                        onChange={(event:any) => setItem((prevState:any) => ({
                          ...prevState,
                            regNum: event.target.value,
                        }))}
                        InputProps={{endAdornment: <SearchButton />}}
                        size="small"
                    />
              </Grid>
              <Grid item xs={1}>
                <TextField
                        sx={{ m: 1}}
                        label="VAT"
                        placeholder="VAT"
                        value={item.vat}
                        fullWidth
                        onChange={(event:any) => setItem((prevState:any) => ({
                          ...prevState,
                            vat: event.target.value,
                        }))}
                        size="small"
                    />
              </Grid>
            </Grid>
            <TextField
                      sx={{ m: 1}}
                      label="Name"
                      placeholder="Name"
                      value={item.name}
                      fullWidth 
                      onChange={(event:any) => setItem((prevState:any) => ({
                                                    ...prevState,
                                                      name: event.target.value,
                                                  }))}
                      size="small"

                  />

            <TagsInput value={item.otherRegNums} inputProps={{ placeholder:"Enter other regNum"}} onChange={(otherRegNums:any) => setItem((prevState:any) => ({
                                                      ...prevState,
                                                      otherRegNums: otherRegNums,
                                                    }))}
                                                    />
            Address:  
            <Grid container spacing={2} columns={2}>
              <Grid item xs={1}>
                <TextField
                        sx={{ m: 1}}
                        label="Street"
                        placeholder="Street"
                        value={item.street}
                        fullWidth
                        onChange={(event:any) => setItem((prevState:any) => ({
                          ...prevState,
                            street: event.target.value,
                        }))}
                        size="small"
                    />
              </Grid>
              <Grid item xs={1}>
                <TextField
                        sx={{ m: 1}}
                        label="Street Number"
                        placeholder="Street Number"
                        value={item.streetNumber}
                        fullWidth
                        onChange={(event:any) => setItem((prevState:any) => ({
                          ...prevState,
                            streetNumber: event.target.value,
                        }))}
                        size="small"
                    />
              </Grid>
              <Grid item xs={1}>
                <TextField
                        sx={{ m: 1}}
                        label="City"
                        placeholder="City"
                        value={item.city}
                        fullWidth
                        onChange={(event:any) => setItem((prevState:any) => ({
                          ...prevState,
                            city: event.target.value,
                        }))}
                        size="small"
                    />
              </Grid>
              <Grid item xs={1}>
                <TextField
                        sx={{ m: 1}}
                        label="PostCode"
                        placeholder="PostCode"
                        value={item.postCode}
                        fullWidth
                        onChange={(event:any) => setItem((prevState:any) => ({
                          ...prevState,
                            postCode: event.target.value,
                        }))}
                        size="small"
                    />
              </Grid>
              <Grid item xs={1}>
                <FormControl sx={{ m: 1 }}  size="medium" fullWidth>
                  <InputLabel id="demo-select-small-label">Country</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    autoWidth
                    label="Type"
                    value={item.country}
                    onChange={(event: SelectChangeEvent) => setItem((prevState:any) => ({
                      ...prevState,
                        country: event.target.value,
                    }))
              }
                  >
                        <MenuItem value='cz' key='sk'>CZ</MenuItem>
                        <MenuItem value='sk' key='sk'>SK</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <TextField
                        sx={{ m: 1}}
                        label="Databox"
                        placeholder="DataBox"
                        value={item.databox}
                        fullWidth
                        onChange={(event:any) => setItem((prevState:any) => ({
                          ...prevState,
                            databox: event.target.value,
                        }))}
                    />
              </Grid>
              <Grid item xs={1}>
              <Typography sx={{ mt: 1, mb: 1 }} component="div">
                  Emails:
                    <IconButton edge="end" aria-label="delete" onClick={addCompanyEmail}>
                      <Add />
                    </IconButton>
                </Typography>
                <List>
                { item.emails ? 
                  item.emails.map((field:any) => (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => deleteEmail(field.email)}>
                      <DeleteIcon />
                    </IconButton>
                    }
                  >
                  <ListItemAvatar>
                    <Avatar>
                      <Email />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    key={field.email}
                    primary={
                      <Typography variant="inherit" fontWeight={field.emailType==="ET002"?"bold":""}>
                        {field.email}
                      </Typography>}
                    secondary={field.emailTypeLabel}
                  />
                </ListItem>
                )) : ''}
                </List>
              </Grid>
              <Grid item xs={1}>
                <Typography sx={{ mt: 1, mb: 1 }} component="div">
                  Links:
                  <IconButton edge="end" aria-label="delete" onClick={addCompanyLink}>
                      <Add />
                    </IconButton>
                </Typography>
                <List>
                { item.links ? 
                  item.links.map((field:any) => (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => deleteLink(field.link)}>
                      <DeleteIcon/>
                    </IconButton>
                    }
                  >
                  <ListItemAvatar>
                    <Avatar>
                      <Language />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    key={field.link}
                    primary={field.link}
                    secondary={field.linkTypeLabel}
                  />
                </ListItem>
                )) : ''}
                </List>
              </Grid>
            </Grid>


          </TabPanel>

          <TabPanel value="2">

            <WebDetailSelectDialog
                  onItemSelected={addWebDetail} buttonLabel='Add existing web' disabled={item.id==="new"}
                />
            <DataGrid
                rows={item.webs}
                columns={columnsWebs}
                pageSize={13}
                rowsPerPageOptions={[13]}
                disableSelectionOnClick
                autoHeight={true}
                experimentalFeatures={{ newEditingApi: true }}
              />

          </TabPanel>
        </TabContext>

        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleSave}>Save</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEmail}
        onClose={handleEmailClose}
        maxWidth="lg"
        PaperProps={{
          sx: {
            width: 500,
            maxHeight: 300
          }
        }}
      >
        <DialogTitle>New email</DialogTitle>
        <DialogContent dividers style={{height:'400px'}}>
              <TextField
                        sx={{ m: 1, width:400}}
                        label="Email"
                        placeholder="Email"
                        value={newEmail}
                        fullWidth
                        onChange={(event:any) => setNewEmail(event.target.value)}
                        size="small"
                    />

                <FormControl sx={{ m: 1 , width: 400}}  size="medium" fullWidth>
                  <InputLabel id="demo-select-small-label">Email type</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={newEmailType}
                    onChange={(event: SelectChangeEvent) => {setNewEmailType(event.target.value);}}
                    >
                    {codebookEmailType.map((option:any) => (
                        <MenuItem value={option.value} key={option.value}>
                          {option.label}
                        </MenuItem>
                    ))}                  
                  </Select>
                </FormControl>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSaveNewEmail}>Save</Button>
          <Button onClick={handleEmailClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openLink}
        onClose={handleLinkClose}
        maxWidth="lg"
        PaperProps={{
          sx: {
            width: 500,
            maxHeight: 300
          }
        }}
      >
        <DialogTitle>New link</DialogTitle>
        <DialogContent dividers style={{height:'400px'}}>
              <TextField
                        sx={{ m: 1, width:400}}
                        label="Link"
                        placeholder="Link"
                        value={newLink}
                        fullWidth
                        onChange={(event:any) => setNewLink(event.target.value)}
                        size="small"
                    />

                <FormControl sx={{ m: 1 , width: 400}}  size="medium" fullWidth>
                  <InputLabel id="demo-select-small-label">Link type</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={newLinkType}
                    onChange={(event: SelectChangeEvent) => {setNewLinkType(event.target.value);}}
                    >
                    {codebookLinkType.map((option:any) => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}                  
                  </Select>
                </FormControl>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSaveNewLink}>Save</Button>
          <Button onClick={handleLinkClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={notFoundCompany}
        autoHideDuration={3000}
        onClose={notFoundCompanyClose}
      >
          <Alert severity="error" sx={{ width: '100%' }}>
            Can't find company with specified regnum.
          </Alert>
      </Snackbar>

      <ConfirmDialog
        open={isConfirmDialogOpen}
        title="Confirmation"
        message="Are you sure you want to delete this item?"
        onConfirm={handleDelete}
        onCancel={() => { setIsConfirmDialogOpen(false)}}
      />

    </Box>
  );
}